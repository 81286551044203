export const LocationSVG = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 11.5C9 12.2956 9.31607 13.0587 9.87868 13.6213C10.4413 14.1839 11.2044 14.5 12 14.5C12.7956 14.5 13.5587 14.1839 14.1213 13.6213C14.6839 13.0587 15 12.2956 15 11.5C15 10.7044 14.6839 9.94129 14.1213 9.37868C13.5587 8.81607 12.7956 8.5 12 8.5C11.2044 8.5 10.4413 8.81607 9.87868 9.37868C9.31607 9.94129 9 10.7044 9 11.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.657 17.157L13.414 21.4C13.039 21.7746 12.5306 21.9851 12.0005 21.9851C11.4704 21.9851 10.962 21.7746 10.587 21.4L6.343 17.157C5.22422 16.0382 4.46234 14.6127 4.15369 13.0609C3.84504 11.509 4.00349 9.90051 4.60901 8.43873C5.21452 6.97694 6.2399 5.72754 7.55548 4.8485C8.87107 3.96947 10.4178 3.50029 12 3.50029C13.5822 3.50029 15.1289 3.96947 16.4445 4.8485C17.7601 5.72754 18.7855 6.97694 19.391 8.43873C19.9965 9.90051 20.155 11.509 19.8463 13.0609C19.5377 14.6127 18.7758 16.0382 17.657 17.157Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
