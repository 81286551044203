import { SVGProps } from '@/types/common.types';

export const TelegramSVG = (props: SVGProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 13.3333L14.6667 18.6666L22.6667 26.6666L28 5.33325L4 14.6666L9.33333 17.3333L12 25.3333L16 19.9999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
