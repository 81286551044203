import type { SVGProps } from '@/types/common.types';

const FavoriteSecondarySVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8117 6.9737L17.4005 16.0527C17.3359 16.4543 17.1239 16.8205 16.8026 17.0858C16.4814 17.3511 16.072 17.4979 15.6479 17.5H5.34696C4.92287 17.4979 4.51344 17.3511 4.19221 17.0858C3.87098 16.8205 3.65899 16.4543 3.59431 16.0527L2.18343 6.97615C2.1487 6.73375 2.16833 6.48703 2.24103 6.25247C2.31373 6.01791 2.43782 5.8009 2.60502 5.61593C2.77222 5.43096 2.97868 5.28228 3.21062 5.17981C3.44256 5.07735 3.69464 5.02346 3.95005 5.02174H16.5822C17.8026 4.84806 19.0114 5.73492 18.8117 6.9737ZM11.8467 9.70024L13.6126 9.95899C13.7262 9.97705 13.8325 10.0264 13.9199 10.1017C14.0073 10.1769 14.0724 10.275 14.1079 10.3853C14.1434 10.4955 14.148 10.6134 14.1211 10.7261C14.0942 10.8388 14.037 10.9418 13.9556 11.0236L12.6779 12.2792L12.9796 14.053C12.9991 14.1677 12.9863 14.2856 12.9428 14.3934C12.8993 14.5012 12.8268 14.5946 12.7335 14.663C12.6402 14.7314 12.5297 14.7722 12.4146 14.7807C12.2995 14.7892 12.1844 14.765 12.0822 14.711L10.5021 13.8735L8.92252 14.7106C8.82036 14.7648 8.70523 14.789 8.59013 14.7806C8.47503 14.7723 8.36454 14.7316 8.27115 14.6632C8.17776 14.5949 8.10519 14.5016 8.06163 14.3938C8.01807 14.286 8.00526 14.1681 8.02465 14.0534L8.32636 12.2792L7.04864 11.0232C6.96603 10.942 6.90759 10.8391 6.8799 10.7262C6.85221 10.6132 6.85639 10.4947 6.89196 10.384C6.92752 10.2733 6.99306 10.1749 7.08118 10.0998C7.16929 10.0247 7.27648 9.97592 7.39063 9.95899L9.15755 9.70024L9.94727 8.08664C9.99835 7.98222 10.0774 7.8943 10.1755 7.83282C10.2735 7.77134 10.3867 7.73876 10.5021 7.73876C10.6176 7.73876 10.7308 7.77134 10.8288 7.83282C10.9269 7.8943 11.0059 7.98222 11.057 8.08664L11.8467 9.70024Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M5.5 4C5.5 2.89543 6.39543 2 7.5 2H17.5C18.6046 2 19.5 2.89543 19.5 4V4.5C19.5 4.77614 19.2761 5 19 5H6C5.72386 5 5.5 4.77614 5.5 4.5V4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FavoriteSecondarySVG;
