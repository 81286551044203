import type { SVGProps } from '@/types/common.types';

const PromoSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00008 17.5C3.15913 17.5 1.66675 16.0076 1.66675 14.1667V13.3333C1.66675 12.8731 2.05008 12.5136 2.48499 12.363C3.46386 12.0241 4.16675 11.0941 4.16675 10C4.16675 8.90585 3.46386 7.97588 2.48499 7.63698C2.05008 7.4864 1.66675 7.1269 1.66675 6.66667V5.83333C1.66675 3.99238 3.15913 2.5 5.00008 2.5H8.33342H15.0001C16.841 2.5 18.3334 3.99238 18.3334 5.83333V6.66667C18.3334 7.1269 17.9501 7.4864 17.5152 7.63698C16.5363 7.97588 15.8334 8.90585 15.8334 10C15.8334 11.0941 16.5363 12.0241 17.5152 12.363C17.9501 12.5136 18.3334 12.8731 18.3334 13.3333V14.1667C18.3334 16.0076 16.841 17.5 15.0001 17.5H8.33342H5.00008ZM8.95842 5C8.95842 4.65482 8.67859 4.375 8.33342 4.375C7.98824 4.375 7.70842 4.65482 7.70842 5V6.66667C7.70842 7.01184 7.98824 7.29167 8.33342 7.29167C8.67859 7.29167 8.95842 7.01184 8.95842 6.66667V5ZM8.33342 12.7083C8.67859 12.7083 8.95842 12.9882 8.95842 13.3333V15C8.95842 15.3452 8.67859 15.625 8.33342 15.625C7.98824 15.625 7.70842 15.3452 7.70842 15V13.3333C7.70842 12.9882 7.98824 12.7083 8.33342 12.7083ZM8.95842 9.16667C8.95842 8.82149 8.67859 8.54167 8.33342 8.54167C7.98824 8.54167 7.70842 8.82149 7.70842 9.16667V10.8333C7.70842 11.1785 7.98824 11.4583 8.33342 11.4583C8.67859 11.4583 8.95842 11.1785 8.95842 10.8333V9.16667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PromoSVG;
