import type { SVGProps } from '@/types/common.types';

const FavoriteSVG = (props: SVGProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="heart">
        <path
          id="Vector"
          d="M16.2501 10.4767L10.0001 16.6667L3.75009 10.4767C3.33784 10.0755 3.01312 9.59337 2.79638 9.06055C2.57963 8.52774 2.47556 7.95582 2.4907 7.3808C2.50585 6.80579 2.63989 6.24014 2.88439 5.71947C3.12888 5.19881 3.47853 4.7344 3.91133 4.35551C4.34412 3.97661 4.85068 3.69143 5.3991 3.51793C5.94752 3.34442 6.52593 3.28635 7.09789 3.34736C7.66986 3.40837 8.223 3.58716 8.72248 3.87245C9.22196 4.15774 9.65696 4.54336 10.0001 5.00502C10.3447 4.54671 10.7802 4.16446 11.2793 3.8822C11.7785 3.59993 12.3305 3.42373 12.9009 3.36463C13.4712 3.30552 14.0477 3.36477 14.5941 3.53869C15.1405 3.7126 15.6451 3.99742 16.0764 4.37533C16.5077 4.75324 16.8563 5.2161 17.1004 5.73494C17.3446 6.25378 17.479 6.81743 17.4953 7.39062C17.5117 7.9638 17.4095 8.53419 17.1952 9.06607C16.9809 9.59795 16.6592 10.0799 16.2501 10.4817"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default FavoriteSVG;
