export const YoutubeSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M4 12C4 10.5855 4.5619 9.22896 5.5621 8.22876C6.56229 7.22857 7.91885 6.66667 9.33333 6.66667H22.6667C24.0812 6.66667 25.4377 7.22857 26.4379 8.22876C27.4381 9.22896 28 10.5855 28 12V20C28 21.4145 27.4381 22.771 26.4379 23.7712C25.4377 24.7714 24.0812 25.3333 22.6667 25.3333H9.33333C7.91885 25.3333 6.56229 24.7714 5.5621 23.7712C4.5619 22.771 4 21.4145 4 20V12Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 12L20 16L13.3333 20V12Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
