export const InstagramSVG = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9999 9.99992V10.0133M5.33325 10.6666C5.33325 9.2521 5.89515 7.89554 6.89535 6.89535C7.89554 5.89515 9.2521 5.33325 10.6666 5.33325H21.3333C22.7477 5.33325 24.1043 5.89515 25.1045 6.89535C26.1047 7.89554 26.6666 9.2521 26.6666 10.6666V21.3333C26.6666 22.7477 26.1047 24.1043 25.1045 25.1045C24.1043 26.1047 22.7477 26.6666 21.3333 26.6666H10.6666C9.2521 26.6666 7.89554 26.1047 6.89535 25.1045C5.89515 24.1043 5.33325 22.7477 5.33325 21.3333V10.6666ZM11.9999 15.9999C11.9999 17.0608 12.4213 18.0782 13.1715 18.8283C13.9216 19.5785 14.9391 19.9999 15.9999 19.9999C17.0608 19.9999 18.0782 19.5785 18.8283 18.8283C19.5785 18.0782 19.9999 17.0608 19.9999 15.9999C19.9999 14.9391 19.5785 13.9216 18.8283 13.1715C18.0782 12.4213 17.0608 11.9999 15.9999 11.9999C14.9391 11.9999 13.9216 12.4213 13.1715 13.1715C12.4213 13.9216 11.9999 14.9391 11.9999 15.9999Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
