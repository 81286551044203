import type { SVGProps } from '@/types/common.types';

const VideoClipSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M16.6667 14.6667V4H2V14.6667H16.6667ZM16.6667 14.6667L16.6668 6.66676H19.3335V17.3334M16.6667 14.6667L4.66684 14.6668V17.3334H19.3335M19.3335 17.3334L19.3332 9.33338H21.9999V20.0001H7.33325V17.3334L19.3335 17.3334ZM8.00017 7.33336V11.3334L11.3335 9.33336L8.00017 7.33336Z"
        stroke="currentColor"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoClipSVG;
