import type { SVGProps } from '@/types/common.types';

const CollectionsSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M0.666748 2.66663C0.666748 1.56206 1.56218 0.666626 2.66675 0.666626H6.16675C7.27132 0.666626 8.16675 1.56206 8.16675 2.66663V4.49996C8.16675 5.60453 7.27132 6.49996 6.16675 6.49996H2.66675C1.56218 6.49996 0.666748 5.60453 0.666748 4.49996V2.66663Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
      <path
        d="M0.666748 10.1666C0.666748 9.06206 1.56218 8.16663 2.66675 8.16663H6.16675C7.27132 8.16663 8.16675 9.06206 8.16675 10.1666V15.3333C8.16675 16.4379 7.27132 17.3333 6.16675 17.3333H2.66675C1.56218 17.3333 0.666748 16.4379 0.666748 15.3333V10.1666Z"
        fill="currentColor"
      />
      <path
        d="M9.8335 2.66663C9.8335 1.56206 10.7289 0.666626 11.8335 0.666626H15.3335C16.4381 0.666626 17.3335 1.56206 17.3335 2.66663V7.83329C17.3335 8.93786 16.4381 9.83329 15.3335 9.83329H11.8335C10.7289 9.83329 9.8335 8.93786 9.8335 7.83329V2.66663Z"
        fill="currentColor"
      />
      <path
        d="M9.8335 13.5C9.8335 12.3954 10.7289 11.5 11.8335 11.5H15.3335C16.4381 11.5 17.3335 12.3954 17.3335 13.5V15.3333C17.3335 16.4379 16.4381 17.3333 15.3335 17.3333H11.8335C10.7289 17.3333 9.8335 16.4379 9.8335 15.3333V13.5Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default CollectionsSVG;
