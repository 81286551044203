import type { SVGProps } from '@/types/common.types';

const ArrowTopSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      {...props}
    >
      <path
        d="M12.5001 14.3661C11.813 14.3661 11.154 14.6391 10.6681 15.125C10.1822 15.6109 9.90918 16.2699 9.90918 16.9571V20.3253C9.90918 20.85 10.3345 21.2753 10.8592 21.2753H14.1411C14.6658 21.2753 15.0911 20.85 15.0911 20.3253V16.9571C15.0911 16.2699 14.8181 15.6109 14.3322 15.125C13.8463 14.6391 13.1873 14.3661 12.5001 14.3661Z"
        fill="currentColor"
      />
      <path
        d="M13.3168 3.05117C12.8585 2.61573 12.1393 2.61576 11.681 3.05125L3.88642 10.4573C3.32013 10.9954 2.99951 11.7423 2.99951 12.5234V20.4116C2.99951 20.6406 3.0905 20.8603 3.25247 21.0223C3.41444 21.1842 3.63411 21.2752 3.86317 21.2752H7.23143C7.75611 21.2752 8.18145 20.8499 8.18145 20.3252V16.9569C8.18145 15.8117 8.63641 14.7133 9.44625 13.9035C10.2561 13.0936 11.3545 12.6387 12.4997 12.6387C13.645 12.6387 14.7434 13.0936 15.5532 13.9035C16.3631 14.7133 16.818 15.8117 16.818 16.9569V20.3252C16.818 20.8499 17.2434 21.2752 17.768 21.2752H21.1363C21.3654 21.2752 21.585 21.1842 21.747 21.0223C21.909 20.8603 22 20.6406 22 20.4116V12.5236C22 11.7423 21.6793 10.9954 21.1129 10.4573L13.3168 3.05117Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowTopSVG;
