import type { SVGProps } from '@/types/common.types';

const OnlineTvSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.0479 2.29736H3.95271C2.9664 2.29736 2.16699 3.09677 2.16699 4.08308V12.4938C2.16699 13.4801 2.9664 14.2795 3.95271 14.2795H17.0479C18.0343 14.2795 18.8337 13.4801 18.8337 12.4938V4.08308C18.8337 3.09677 18.0343 2.29736 17.0479 2.29736ZM12.2813 9.05986L10.5539 10.2807C9.9283 10.723 9.06402 10.2753 9.06402 9.50927V7.0682C9.06402 6.30212 9.9283 5.85451 10.5539 6.29677L12.2813 7.5176C12.814 7.8932 12.814 8.68308 12.2813 9.05986Z"
        fill="currentColor"
      />
      <path
        d="M15.0367 16.6812C14.4783 16.4229 13.5075 16.2324 12.3391 16.133V15.3508H8.66048V16.133C7.49203 16.2318 6.5212 16.4223 5.96286 16.6812C5.69441 16.805 5.57774 17.1234 5.70215 17.3919C5.82536 17.6598 6.14322 17.777 6.41286 17.6526C6.97774 17.3919 8.46108 17.1276 10.4998 17.1276C12.5385 17.1276 14.0218 17.3913 14.5867 17.6526C14.6593 17.6865 14.7361 17.7026 14.8111 17.7026C15.0135 17.7026 15.2075 17.5877 15.2974 17.3919C15.4224 17.1234 15.3051 16.805 15.0367 16.6812Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OnlineTvSVG;
