import type { SVGProps } from '@/types/common.types';

const SearchSecondarySVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      {...props}
    >
      <path
        d="M9.75 7.5C9.75 6.5335 8.9665 5.75 8 5.75C7.0335 5.75 6.25 6.5335 6.25 7.5C6.25 8.4665 7.0335 9.25 8 9.25C8.9665 9.25 9.75 8.4665 9.75 7.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 3V14.5V15.25C0.5 17.0449 1.98027 18.5 3.77519 18.5C5.5425 18.5 7 17.0673 7 15.3C7 14.8582 7.35817 14.5 7.8 14.5H16.5V3C16.5 1.34315 15.1569 0 13.5 0H3.5C1.84315 0 0.5 1.34315 0.5 3ZM11.25 7.5C11.25 5.70507 9.79493 4.25 8 4.25C6.2051 4.25 4.75 5.70507 4.75 7.5C4.75 9.2949 6.2051 10.75 8 10.75C8.62574 10.75 9.21018 10.5732 9.70607 10.2667L10.9697 11.5303C11.2626 11.8232 11.7374 11.8232 12.0303 11.5303C12.3232 11.2374 12.3232 10.7626 12.0303 10.4697L10.7667 9.2061C11.0732 8.7102 11.25 8.1257 11.25 7.5Z"
        fill="currentColor"
      />
      <path
        d="M16.5 20C18.3675 20 19.9361 18.7202 20.3766 16.9899C20.5128 16.4547 20.0523 16 19.5 16H9.5C8.9477 16 8.5128 16.4547 8.37657 16.9899C7.93606 18.7202 6.36748 20 4.5 20H16.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchSecondarySVG;
