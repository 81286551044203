import type { SVGProps } from '@/types/common.types';

const CompetitionsSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8 21H16M12 17V21M12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12V4H7V12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17ZM3 9C3 9.53043 3.21071 10.0391 3.58579 10.4142C3.96086 10.7893 4.46957 11 5 11C5.53043 11 6.03914 10.7893 6.41421 10.4142C6.78929 10.0391 7 9.53043 7 9C7 8.46957 6.78929 7.96086 6.41421 7.58579C6.03914 7.21071 5.53043 7 5 7C4.46957 7 3.96086 7.21071 3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9ZM17 9C17 9.53043 17.2107 10.0391 17.5858 10.4142C17.9609 10.7893 18.4696 11 19 11C19.5304 11 20.0391 10.7893 20.4142 10.4142C20.7893 10.0391 21 9.53043 21 9C21 8.46957 20.7893 7.96086 20.4142 7.58579C20.0391 7.21071 19.5304 7 19 7C18.4696 7 17.9609 7.21071 17.5858 7.58579C17.2107 7.96086 17 8.46957 17 9Z"
        stroke="currentColor"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompetitionsSVG;
