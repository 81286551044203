import type { SVGProps } from '@/types/common.types';

const PrivacyPolicySVG = (props: SVGProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 640" x="0px" y="0px" {...props}>
      <g>
        <path
          fill="currentColor"
          d="M474.54,212.46c-1.41-73.5-11.65-127.71-11.51-127.76-4.26-8.31-11.73-15.26-20.87-18.81h0C382.16,42.62,323.16,20.41,265.64,1.46c-5.88-1.94-12.08-1.94-17.95,0-57.51,18.9-116.5,41.07-176.49,64.31-8.07,3.13-14.84,8.92-19.25,15.96,.34,.04-12.33,39.59-14.49,126.94-1.28,87.36,9.6,113.53,9.24,113.56,5.89,19.2,16.91,38.73,32.7,58.59,17.65,22.18,41.5,45.09,70.31,67.61,47.69,37.43,92.32,59.8,94.19,60.59,4.05,1.98,8.34,2.99,12.76,2.98,4.43,0,8.72-1.01,12.76-3,1.88-.79,46.56-23.23,94.23-60.65,28.82-22.54,52.67-45.45,70.33-67.62,16.92-21.27,28.36-42.14,33.89-62.64-.22-.03,7.78-32.11,6.67-105.62Zm-194.14,57.33v46.57c0,13.12-10.63,23.75-23.75,23.76-13.12,0-23.76-10.63-23.76-23.75v-45.92c-17.12-8.51-28.9-26.17-28.9-46.58,0-28.72,23.28-52.01,52-52.01,28.72,0,52,23.28,52.01,52,0,19.9-11.18,37.19-27.6,45.93Z"
        />
      </g>
    </svg>
  );
};

export default PrivacyPolicySVG;
